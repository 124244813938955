<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Informasi User</h2>
      </div>
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24">
          <div>
            <h1 class="text-title">User</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">ID (4 HURUF)</p>
                  <a-input placeholder="Masukkan Id User" v-model:value="payload.userSign" disabled></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nama</p>
                  <a-input placeholder="Masukkan Nama User" v-model:value="payload.username" disabled></a-input>
                </div>
              </a-col>
              <!-- <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Password</p>
                  <a-input placeholder="duaribusatu" disabled></a-input>
                </div>
              </a-col> -->
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Email</p>
                  <a-input placeholder="Masukkan Email User" v-model:value="payload.email" disabled></a-input>
                </div>
              </a-col>
              <!-- <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nomor Handphone</p>
                  <a-input placeholder="Masukkan Nomor Handphone" v-model:value="payload.mobileNumber"></a-input>
                </div>
              </a-col> -->
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tipe User</p>
                  <a-select placeholder="Pilih Tipe Vendor" class="w-100" v-model:value="payload.role" :disabled="true">
                    <a-select-option value="admin">Admin</a-select-option>
                    <a-select-option value="receptionist">Receptionist</a-select-option>
                    <a-select-option value="vendor">Vendor</a-select-option>
                    <a-select-option value="dcOwner">DC Owner</a-select-option>
                    <a-select-option value="projectManager">Project Manager</a-select-option>
                    <a-select-option value="siteManager">Site Manager</a-select-option>
                    <a-select-option value="eos">EOS</a-select-option>
                    <a-select-option value="helpdesk">Help Desk</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <!-- <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="clearPayload()" type="primary">Clear</a-button>
              <a-button @click="submitPayload()">Submit</a-button>
            </a-col>
          </a-row>
        </a-col> -->
      </a-row>
      <a-row class="mt-5" :gutter="[32, 32]">
        <a-col :span="24">
          <div>
            <h1 class="text-title">Change Password</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">

              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Old Password</p>
                  <a-input-password placeholder="Masukkan Password Lama"
                    v-model:value="payload.oldPassword"></a-input-password>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">New Password</p>
                  <a-input-password placeholder="Masukkan Password Baru"
                    v-model:value="payload.newPassword"></a-input-password>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="clearPayload()" type="primary">Clear</a-button>
              <a-button @click="submitPayload()">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount, watch } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  LeftOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification, Form } from 'ant-design-vue'
import { getCustomers, getLocations, postUser, getUsersById, putUser, changePassword } from '../../services/axios/modc/users'


export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const idUser = route.params.id
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const payload = ref({
    })
    let customersOptions = ref([])
    let locationsOptions = ref([])
    const clearPayload = () => {
      payload.value = {
        ...payload.value,
        oldPassword: '',
        newPassword: '',
      }
    }

    const submitPayload = async () => {
      const data = await changePassword({ email: payload.value.email, oldPassword: payload.value.oldPassword, newPassword: payload.value.newPassword })
      console.log(data)
      if (data.message === 'Success change password') {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Password User Berhasil Diubah',
        })
        router.push('/dashboard')

      }
    }

    const fetchUsers = async (search = '') => {
      const storedUserData = localStorage.getItem('userData');
      const userData = JSON.parse(storedUserData);
      console.log(userData)
      const { users } = await getUsersById(userData.user.id)
      // console.log(customer)
      payload.value = {
        userSign: users.userSign,
        username: users.username,
        email: users.email,
        oldPassword: '',
        mobileNumber: users.mobileNumber,
        role: users.role,
      }
    }

    const fetchLocations = async (search = '') => {
      const { locations } = await getLocations(payload.value.customers)
      const locationsData = locations.map(e => ({ value: e.name }),
      )
      locationsOptions.value = locationsData
    }

    onMounted(() => {
      fetchUsers()
    })
    watch(payload.value, (newValue, oldValue) => {
      fetchLocations();
    });


    return {
      payload,
      clearPayload,
      submitPayload,
      customersOptions,
      locationsOptions,
      fetchUsers,
      fetchLocations,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
